.dropbtn {
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer; }

.customDropdown {
    position: relative;
    display: inline-block;
    border: 1px solid #DDE4F4;
    border-radius: 4px;
    box-sizing: border-box;
    width: 250px;
    height: 40px; }

.dropdown-content {
    display: none;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: 15px;
    width: 250px; }

.dropdown-content span {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block; }

.dropdown-content span:hover {
    background-color: #f1f1f1; }

.customDropdown:hover .dropdown-content {
    display: block; }

.dropDownArrow {
    border: 2px solid black;
    border-width: 0 2px 2px 0;
    padding: 3px;
    position: relative;
    right: 20px;
    bottom: -5px;
    float: right; }

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.dropDownBtnWrapper {
    position: relative;
    top: 9px;
    left: 10px; }
